<template>
  <div class="header-partial">
    <div class="header-partial__left-col">
      <button
        class="header-partial__btn-back"
        @click="back"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          size="20"
        />
        <slot name="left" />
      </button>
    </div>
    <div class="header-partial__center-col">
      <slot name="center" />
    </div>
    <div class="header-partial__right-col">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderPartial',
  methods: {
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/include";

.header-partial{
  height: 62px;
  background: $white;
  border-radius: 6px;
  box-shadow: 0px 4px 24px rgba(24, 41, 47, 0.1);
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__btn-back{
    border: 0;
    background: 0;
    color: $gray-100;
    padding: 10px;
    margin: -10px;
    &:hover{
      color: $secondary;
    }
  }

  &__center-col{
    margin-right: 30px;
    margin-left: 30px;
  }
}
</style>
