<template>
  <b-modal
    id="modal-selected-site-type"
    class="selected-site-type-modal"
    centered
    title="Выберите тип сайта"
    modal-class="modal-primary"
    ok-title="Выбрать"
    cancel-title="Отмена"
    size="lg"
    @show="clearModal"
    @hidden="clearModal"
    @ok="handleOk"
  >
    <div class="selected-site-type-modal__items">
      <div
        v-for="item in items"
        :key="item.value"
        class="selected-site-type-modal__item"
        :class="{
          'selected-site-type-modal__item_disabled': item.disabled,
          'selected-site-type-modal__item_selected': item.value === selectedType
        }"
        @click="selectItem(item)"
      >
        <div class="selected-site-type-modal__item__icon">
          <feather-icon
            :icon="item.icon"

            size="3x"
          />
        </div>
        <div class="selected-site-type-modal__item__title">
          {{ item.name }}
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BOverlay,
  BFormInput,
} from 'bootstrap-vue'

import { getCurrentInstance, ref } from 'vue'

// eslint-disable-next-line import/no-named-default
import { futureTypes, default as siteTypes } from '../composables/siteTypes'

export default {
  name: 'SelectedSiteTypeModel',
  components: {
    BModal,
    BFormGroup,
    BOverlay,
    BFormInput,
  },
  setup() {
    const instance = getCurrentInstance().proxy

    const selectedType = ref(siteTypes[0].value)

    const clearModal = () => {
      console.log('clearModal')
    }

    const handleOk = async bvModalEvt => {
      bvModalEvt.preventDefault()
      instance.$router.push({
        name: `sites.${selectedType.value}.create`,
      })
    }

    // onMounted(() => {
    //   instance.$bvModal.show('modal-selected-site-type')
    // })

    const items = [
      ...siteTypes,
      ...futureTypes.map(i => ({
        ...i,
        disabled: true,
      })),
    ]

    const selectItem = item => {
      if (item.disabled) {
        return
      }
      selectedType.value = item.value
    }

    return {
      clearModal,
      handleOk,

      items,
      selectedType,

      selectItem,
    }
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/_include'; // Bootstrap includes
@import '~@core/scss/base/components/_include'; // Components includes

.selected-site-type-modal{

  &__items{
    padding-top: 30px;
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    grid-row-gap: 20px;

    @include media-breakpoint-down(md){
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item{
    cursor: pointer;
    height: 180px;
    background: #FFFFFF;
    box-shadow: 0 3px 15px rgba(75, 70, 92, 0.1);
    border-radius: 6px;
    border: 1px solid #ffffff;

    &_disabled{
      cursor: not-allowed;
      opacity: 0.5;
    }

    &_selected{
      border: 1px solid $primary;
      color: $primary;
    }

    &__title{
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      // color: #82868B;
      margin-top: 20px;
    }

    &__icon{
      text-align: center;
      margin-top: 50px;
    }

  }
}
</style>
