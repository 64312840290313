<template>
  <h-layout class="sites-page">
    <template #header>
      <header-partial>
        <template #right>
          <b-button
            v-if="$store.getters['workingMode/checkPermission']('manage_sites')"
            v-b-modal.modal-selected-site-type
            variant="primary"
          >
            Создать
          </b-button>
        </template>
      </header-partial>
    </template>

    <b-overlay
      :show="loading"
      spinner-variant="primary"
      class="sites-page__overlay"
    >
      <app-breadcrumb
        :breadcrumb="breadcrumb"
      />
      <b-card>
        <b-alert
          v-if="!storeSites.length && !externalSites.length"
          variant="secondary"
          show
          class="mb-0"
        >
          <div class="alert-body">
            У вас пока нет сайта, создайте его.
          </div>
        </b-alert>
        <div class="sites-page__types">
          <div
            v-if="externalSites.length"
            class="sites-page__type"
          >
            <div class="sites-page__type__title">
              Внешние
            </div>
            <div class="sites-page__type__items">
              <div
                v-for="itemExternalSite in externalSites"
                :key="itemExternalSite.id + 'external'"
                class="sites-page__type__item"
              >
                <site-card
                  :site="itemExternalSite"
                  :type="'external'"
                  @delete="deleteExternalSite"
                />
              </div>
            </div>
          </div>
          <div
            v-if="storeSites.length"
            class="sites-page__type"
          >
            <div class="sites-page__type__title">
              Интернет-магазины
            </div>
            <div class="sites-page__type__items">
              <div
                v-for="itemStoreSite in storeSites"
                :key="itemStoreSite.id + 'store'"
                class="sites-page__type__item"
              >
                <site-card
                  :site="itemStoreSite"
                  :type="'store'"
                  @delete="deleteStoreSite"
                />
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-overlay>
    <selected-site-type-model
      ref="selected-site-type-model"
    />
  </h-layout>

</template>

<script>
import {
  getCurrentInstance, onMounted, ref,
} from 'vue'
import {
  BAlert, BButton, BCard, BOverlay,
} from 'bootstrap-vue'
import useDeleteItem from '@/composables/useDeleieItem'
import { deleteWebsite as deleteExternalWebsite, getWebsites as getExternalWebsites } from '@/services/main-api/websites/externals/websites'
import { deleteWebsite as deleteStoreWebsite, getWebsites as getStoreWebsites } from '@/services/main-api/websites/stores/websites'
import HeaderPartial from '@/components/partials/HeaderPartial.vue'
import HLayout from '@/components/sub-layout/HLayout.vue'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import SiteCard from '@/modules/sites-section/sites/components/SiteCard.vue'
import siteTypes from '../composables/siteTypes'
import siteModes from '../composables/siteModes'
import SelectedSiteTypeModel from '../components/SelectedSiteTypeModel.vue'

export default {
  name: 'ListView',
  components: {
    SiteCard,
    HeaderPartial,
    HLayout,

    BButton,
    BOverlay,
    BCard,
    BAlert,

    AppBreadcrumb,
    SelectedSiteTypeModel,
  },
  setup() {
    const instance = getCurrentInstance().proxy
    const loading = ref(false)

    const externalSites = ref([])
    const storeSites = ref([])

    const getExternalSites = async () => {
      try {
        const res = await getExternalWebsites({
          projectID: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 1000,
          sortedBy: 'asc',
          orderBy: 'name',
        })

        externalSites.value = res.data.data
      } catch (e) {
        externalSites.value = []
      }
    }

    const getStoreSites = async () => {
      try {
        const res = await getStoreWebsites({
          projectID: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 1000,
          sortedBy: 'asc',
          orderBy: 'name',
        })

        storeSites.value = res.data.data
      } catch (e) {
        storeSites.value = []
      }
    }

    const getItems = async () => {
      await Promise.all([
        getExternalSites(),
        getStoreSites(),
      ])
    }

    const getData = async () => {
      loading.value = true
      await getItems()
      loading.value = false
    }

    const { deleteItem: deleteStoreSite } = useDeleteItem({
      deleteHandler: item => deleteStoreWebsite(item.id),
      getQuestionTextBeforeDeletion: item => `Сайт <strong>${item.name}</strong> будет удален`,
      getTextAfterDeletion: () => 'Сайт был удален.',
      runAfterDeletion: async () => {
        await getData()
      },
    })

    const { deleteItem: deleteExternalSite } = useDeleteItem({
      deleteHandler: item => deleteExternalWebsite(item.id),
      getQuestionTextBeforeDeletion: item => `Сайт <strong>${item.name}</strong> будет удален`,
      getTextAfterDeletion: () => 'Сайт был удален.',
      runAfterDeletion: async () => {
        await getData()
      },
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      } else {
        getData()
      }
    })

    // BEGIN BreadCrumb
    const breadcrumb = [
      {
        text: 'Сайты',
        active: true,
      },
    ]
    // END BreadCrumb

    return {
      loading,
      storeSites,
      externalSites,
      breadcrumb,

      siteTypes,
      siteModes,

      deleteStoreSite,
      deleteExternalSite,
    }
  },
}
</script>

<style lang="scss" scoped>
.sites-page{
  &__overlay {
    min-height: 100%;
  }

  &__type{
    margin-bottom: 25px;

    &:last-child{
      margin-bottom: 0;
    }

    &__title{
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 14px;
    }

    &__items{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      grid-gap: 30px;
      grid-row-gap: 30px;
    }
  }
}
</style>
