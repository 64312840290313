<template>
  <div class="site-card">
    <router-link
      :to="{ name: `sites.${type}.show`, params: { id: site.id } }"
      tag="a"
      class="site-card__top"
    />
    <div class="site-card__body">
      <b-row>
        <b-col style="min-width: 0;">
          <router-link
            class="site-card__title"
            :to="{ name: `sites.${type}.show`, params: { id: site.id } }"
            tag="a"
          >
            {{ site.name }}
          </router-link>
          <div class="site-card__description">
            {{ site.description }}
          </div>
        </b-col>
        <b-col cols="auto">
          <b-dropdown
            class="site-card__dropdown"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            size="sm"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: `sites.${type}.show`, params: { id: site.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Посмотреть</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: `sites.${type}.edit`, params: { id: site.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Изменить</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteSite">
              <feather-icon
                icon="TrashIcon"
              />
              <span class="align-middle ml-50">Удалить</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import {
  computed,
} from 'vue'

import {
  BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  name: 'SiteCard',
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true, // external, store
    },
  },
  setup(props, { emit }) {
    const domain = computed(() => props.site.domain)

    const deleteSite = () => {
      emit('delete', props.site)
    }

    return {
      domain,
      deleteSite,
    }
  },
}
</script>

<style scoped lang="scss">
.site-card {
  background: #FFFFFF;
  /* Light/Gray/Card */

  box-shadow: 0px 4px 18px rgba(75, 70, 92, 0.1);
  border-radius: 6px;
  &__top{
    display: block;
    background: linear-gradient(50.59deg, #8DD3FF 0%, #20A9FF 91.96%);
    width: 100%;
    height: 0;
    padding-bottom: calc(100%/272*173);
    border-radius: 6px 6px 0 0;
  }

  &__body{
    padding: 20px;
  }

  &__title{
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    display: block;
    color: inherit;

    // one line text
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__description{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    // one line text
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__dropdown{
    margin-right: -20px;
  }
}
</style>
